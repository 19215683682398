html {
    scroll-behavior: smooth;
    background-color: #fff;
}

h1 {
    font-size: 64px;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 1000;
}
.breadcrumb-container {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 1000;
}

.content-container {
    display: flex;
    flex-direction: column;
    max-width: 2048px;
    margin: 0 auto;
}

.full {
    height: 100vh;
    width: calc(100% - 260px);
    margin: 0 130px;
    min-height: 700px;
    max-height: 1440px;
}

.splash { 
    max-height: unset;
}

.form-container {
    width: 440px;
    /* height: 679px; */
    background-color: #fff;
    border: 1px solid black;
    position: absolute;
    right: 60px;
    top: 15px;
}

.splash-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
}
a.big.button {
    text-decoration: none;
    border: 2px solid black;
    font-size: 24px;
    padding: 18px 60px;
}
.button-container {
    display: flex;
    margin-top: 40px;
    gap: 60px;
    flex-wrap: wrap;
}
.subtitle {
    font-size: 24px;
    margin-top: 20px;
}
.content {
    padding-top: 75px;
    padding-bottom: 75px;
    position: relative;
}
.wrapper {
    width: calc(100% - 444px);
    min-width: 330px;
}
.wrapper > ul {
    list-style: unset;
    margin-bottom: 20px;
    font-size: 18px;
}
.wrapper > ol {
    list-style: decimal;
    margin-bottom: 20px;
    font-size: 18px;
}

.wrapper > p {
    font-size: 18px;
}

h2 {
    background: black;
    color: white;
    width: fit-content;
    padding: 8px;
    font-size: 24px;
    text-transform: capitalize;
    margin-bottom: 40px;
}

.input-field {
    border: none;
    border-bottom: 1px solid #111111a3;
    width: 100%;
    height: 44px;
    font-size: 18px;
    padding: 0.5rem 0.2rem;
}

.input-field:focus {
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
}
.form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
}
p.form-notification {
    color: #6b7280;
    font-size: 12px !important;
}

p.form-text {
    margin: 0px;
    font-size: 14px;
}
.signature {
    background: #f6f6f6;
    opacity: 0.8;
}

.submit-button {
    font-size: 22px;
    height: 51px;
}

.submit-button:hover {
    background-color: #f5f5f561;
    color: #111;
}
.form-error {
    border-bottom: 1px solid tomato;
}
.form-error:focus {
    border: none;
    border-bottom: 1px solid tomato;
    outline: none;
}

p.response {
    font-size: 12px;
    margin: 0;
    padding: 0;
}
.server-error {
    color: tomato;
}
.server-success {
    color: rgb(99, 216, 99);
}

.hash-anchor {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
}

.breadcrumbs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 130px;
    height: 768px;
    position: absolute;
    justify-content: center;
    left: 12px;
}

.breadcrumbs > div {
    background-color: white;
    overflow: hidden;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    border: 1px dashed black;
    text-align: center;
    transition: width 0.1s ease;
}

.breadcrumbs > div > a {
    visibility: hidden;
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
    text-decoration: none;
    width: 100%;
    height: 100%;
    line-height: 26px;
}

.breadcrumbs > div.active > a {
    color: white;
}
.breadcrumbs > div:hover {
    width: 130px;
    border: 1px solid black;
}

.breadcrumbs > div:hover > a {
    visibility: visible;
}

.breadcrumbs > div.active {
    background-color: black;
}

@media (max-width: 1023px) {
    .sticky {
        position: unset;
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 40px;
    }

    .form-container {
        position: unset;
    }
    .full {
        height: unset;
        width: calc(100% - 120px);
        margin: 0 60px;
    }

    .breadcrumbs {
        display: none;
    }
    .wrapper {        
        width: 100%;
    }
}
@media (max-width: 1190px) and (max-height: 860px) {
    .full {
        height: unset;
    }
  }
  
@media (max-width: 768px) {  
    .full {
        height: unset;
        width: fit-content; 
    }
    .splash {
        height: 100vh;    
    }
    .form-container {
        border: none;
    }
    h1 {
        font-size: 52px;
    }
    
}


@media (max-height: 500px) {  
    .full {
        height: unset;    
    }
    .splash {
        height: 100vh;    
    }
}


@media (max-width: 480px) {      
    .splash {
        height: unset;          
     }
     .full {
        margin: 12px;  
     }
}


@media (max-height: 650px) {  
    .full {
        height: unset;    
    }
}

@media (max-height: 760px) {  
    .sticky {
        position: unset;
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 40px;
    }   
    .form-container {
        position: unset;
    }
}


.spinner-container {
    display: flex;
    justify-content: center;
}

.animation-container {
    display: flex;
    justify-content: center;
}

@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 20px;
    }

    100% {
        stroke-dashoffset: 40px;
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 20px;
    }

    100% {
        stroke-dashoffset: 40px;
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 20px;
    }

    100% {
        stroke-dashoffset: 0px;
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 96px;
    }

    100% {
        stroke-dashoffset: 192px;
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 48px;
    }

    100% {
        stroke-dashoffset: 96px;
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 96px;
    }

    100% {
        stroke-dashoffset: 192px;
    }
}

@keyframes colored-circle {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100;
    }
}

/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
    display: inline;
}

/* .svg img {
    display: none
} */

.icon--order-success svg polyline {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
    -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}
